import "./ComplaintType.scss";
import { Section } from "../Section/Section";
import { useFormContext } from "react-hook-form";
import { useRef, useEffect } from "react";

export const ComplaintType = () => {
  const {
    register,
    formState: { errors },
    watch,
    resetField,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const values = getValues();

  console.log("@@@@", errors)

  return (
    <Section
      title={
        <>
          <img src="/img/squares_icon.svg" alt="squares icon" />
          <h2 id="ComplaintType">Reklamationsart</h2>
        </>
      }
      // description="Wskaż czy Twoje narzędzie znajduje się okresie gwarancyjnym czy jest już po jego zakończeniu. Weryfikacja usterki jest bezpłatna tylko dla narzędzi objętych gwarancją."
      description="Geben Sie an, ob Ihr Werkzeug sich noch in der Garantiezeit befindet oder ob diese bereits abgelaufen ist. Die Überprüfung des Defekts ist nur für Werkzeuge, die unter die Garantie fallen, kostenlos."
    >
      {!!errors.narzedzia?.length && errors.narzedzia[0].typzgloszenia && (
        <p className="error">Wählen Sie die Art der Dienstleistung aus.</p>
      )}
      <div className="complaintType__selection">
        <label>
          <div
            className={
              watch("narzedzia[0].typzgloszenia") === "gwarancja"
                ? "clicked"
                : "complaintType__selection--option"
            }
            onClick={() => {
              resetField("narzedzia[0].akceptkosztownogw");
              if (watch("imie") === '') {
                setError(      
                  "imie",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nazwisko") === '') {
                setError(      
                  "nazwisko",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              // if (watch("nazwafirmy") === '') {
              //   setError(      
              //     "nazwafirmy",
              //     { type: "focus", message: "Pflichtfeld" },
              //     { shouldFocus: true }
              //   );
              // }
              // if (watch("nip") === '') {
              //   setError(      
              //     "nip",
              //     { type: "focus", message: "Pflichtfeld" },
              //     { shouldFocus: true }
              //   );
              // }
              if (watch("nadawcaulica") === '') {
                setError(      
                  "nadawcaulica",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcanrdomu") === '') {
                setError(
                  "nadawcanrdomu",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcakodpocztowy").length < 5) {
                setError(
                  "nadawcakodpocztowy",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcamiasto") === '') {
                setError(
                  "nadawcamiasto",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("tel").length < 9) {
                setError(      
                  "tel",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              if (watch("email") === '') {
                setError(      
                  "email",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
            }}
          >
            <div className="shield"></div>
            <span>Garantie</span>
          </div>
          <input
            {...register("narzedzia[0].typzgloszenia", { required: true })}
            type="radio"
            value="gwarancja"
            name="narzedzia[0].typzgloszenia"
          />
        </label>

        <label>
          <div
            className={
              watch("narzedzia[0].typzgloszenia") === "pogwarancyjna"
                ? "clicked"
                : "complaintType__selection--option"
            }
            onClick={() => {
              resetField("narzedzia[0].akceptkosztownogw");
              if (watch("imie") === '') {
                setError(      
                  "imie",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                )
              } else (clearErrors("imie"))
              if (watch("nazwisko") === '') {
                setError(      
                  "nazwisko",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              // if (watch("nazwafirmy") === '') {
              //   setError(      
              //     "nazwafirmy",
              //     { type: "focus", message: "Pflichtfeld" },
              //     { shouldFocus: true }
              //   );
              // }
              // if (watch("nip") === '') {
              //   setError(      
              //     "nip",
              //     { type: "focus", message: "Pflichtfeld" },
              //     { shouldFocus: true }
              //   );
              // }
              if (watch("nadawcaulica") === '') {
                setError(      
                  "nadawcaulica",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcanrdomu") === '') {
                setError(
                  "nadawcanrdomu",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcakodpocztowy") === '') {
                setError(
                  "nadawcakodpocztowy",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcamiasto") === '') {
                setError(
                  "nadawcamiasto",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              }
              if (watch("tel") === '') {
                setError(      
                  "tel",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
              if (watch("email") === '') {
                setError(      
                  "email",
                  { type: "focus", message: "Pflichtfeld" },
                  { shouldFocus: true }
                );
              } 
            }}
          >
            <div className="wallet"></div>
            <span>außerhalb der Garantie</span>
          </div>
          <input
            {...register("narzedzia[0].typzgloszenia", { required: true })}
            type="radio"
            value="pogwarancyjna"
            name="narzedzia[0].typzgloszenia"
          />
        </label>
      </div>

      {watch("narzedzia[0].typzgloszenia") === "gwarancja" ? (
        <div className="complaintType__checkbox">
          <label className="control control-checkbox">
          Ich erkläre, dass ich mich mit den Garantiebedingungen des Herstellers vertraut gemacht habe und dass die gesendeten Geräte sich im Garantiezeitraum befinden. Falls das Gerät außerhalb der Garantiezeit ist, verpflichte ich mich, die Kosten für Transport und Begutachtung des Geräts zu übernehmen. Die aktuelle Preisliste für Nachgarantieservices finden Sie auf unserer Website www.erpatech.de
            <input
              {...register("narzedzia[0].akceptkosztownogw", {
                required: true,
              })}
              type="checkbox"
              name="narzedzia[0].akceptkosztownogw"
              value="1"
            ></input>
            <div className="control_indicator"></div>
            {!!errors.narzedzia?.length &&
              errors.narzedzia[0].akceptkosztownogw && (
                <p className="error">Markieren Sie die Zustimmung.</p>
              )}
          </label>
        </div>
      ) : watch("narzedzia[0].typzgloszenia") === "pogwarancyjna" ? (
        <div className="complaintType__checkbox">
          <label className="control control-checkbox">
          Im Falle eines Rücktritts von der Reparatur akzeptiere ich die Kosten, die mit der Durchführung der Begutachtung verbunden sind. Die aktuelle Preisliste für Nachgarantieservices finden Sie auf unserer Website www.erpatech.de
            <input
              {...register("narzedzia[0].akceptkosztownogw", {
                required: true,
              })}
              type="checkbox"
              name="narzedzia[0].akceptkosztownogw"
              value="1"
            ></input>
            <div className="control_indicator"></div>
            {!!errors.narzedzia?.length &&
              errors.narzedzia[0].akceptkosztownogw && (
                <p className="error">Markieren Sie die Zustimmung.</p>
              )}
          </label>
        </div>
      ) : null}
    </Section>
  );
};
