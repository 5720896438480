import React from "react";
import ReactDom from "react-dom";
import "./ModalError.scss";
import { useFormContext } from "react-hook-form";
import { ClipboardCopy } from "./ClipboardCopy";
import { StepNumber } from "./components/StepNumber";

export const ModalError = ({ isError, onClose }) => {
  const { getValues} = useFormContext();
  const values = getValues();

  if (!isError) return null;
  return ReactDom.createPortal(
    <div className="ModalError">
      {/* <div className="ModalError__closeBtnContainer">
        <div
          className="ModalError__closeBtnContainer__closeBtn"
          onClick={onClose}
        ></div>
      </div> */}

      <div className="ModalError__content">
        <div className="ModalError__content__header">
          <h1 className="ModalError__content__header__title">Ein Fehler ist aufgetreten</h1>
          <p className="ModalError__content__header__description">Was nun? </p>
        </div>
        <div className="ModalError__content__body">
          <div className="ModalError__content__body__step">
            <StepNumber
              number="1"
              titleText="Klicken Sie auf den Button „Daten kopieren“”"
              descriptionText="Alle im Formular eingegebenen Informationen werden in die Zwischenablage kopiert"
              linename={"step__content__noline"}
            />

            <StepNumber
              number="2"
              titleText="Erstellen Sie eine neue E-Mail"
              descriptionText="Öffnen Sie Ihr E-Mail-Postfach und erstellen Sie eine neue Nachricht an kontakt@erpatech.de"
              mail="kontakt@erpatech.de"
              linename={"step__content__line"}
            />

            <StepNumber
              number="3"
              titleText="In der E-Mail klicken Sie auf „Einfügen””"
              descriptionText="Fügen Sie die kopierten Daten ein und senden Sie die Nachricht. Auf deren Grundlage werden wir die Anmeldung entgegennehmen."
              linename={"step__content__line"}
            />
          </div>
        </div>

        <ClipboardCopy
          copyText={
            "der Anmelder:" +
            "\n" +
            "Vor- und Nachname:" +
            values.imie +
            " " +
            values.nazwisko +
            "\n" +
            "Firmenname und Steuernummer (NIP):" +
            values.nazwafirmy +
            values.nip +
            "\n" +
            "Adresse: " +
            values.nadawcaulica +
            " " +
            values.nadawcanrdomu +
            "/" +
            values.nadawcanrlokalu +
            " " +
            values.nadawcakodpocztowy +
            " " +
            values.nadawcamiasto +
            "\n" +
            "Telefonnummer:" +
            values.tel +
            "\n" +
            "Email:" +
            values.email +
            "\n" +
            "Werkzeug" +
            "\n" +
            "Art der Beschwerde:" +
            values.narzedzia[0].typzgloszenia +
            "\n" +
            "Modell:" +
            values.narzedzia[0].modeltowaru +
            "\n" +
            "Seriennummer:" +
            values.narzedzia[0].nrseryjny +
            "\n" +
            "Kaufdatum:" +
            values.narzedzia[0].datazakupu +
            "\n" +
            "Akzeptierung der Kosten" +
            values.narzedzia[0].akceptkosztownogw +
            "\n" +
            "Beschreibung des Fehlers:" +
            values.narzedzia[0].opisusterki +
            "\n" +
            "Empfang der Sendung" +
            "\n" +
            "Transportunternehmen:" +
            values.typprzesylki +
            "\n" +
            "Paketgröße:" +
            values.rozmiar_paczki +
            "\n" +
            "Abholdatum:" +
            values.dzien_odbioru +
            "\n" +
            "Abholzeit:" +
            values.godzina_odbioru
          }
        />

        <div className="ModalError__content__description">
          <p>Benötigen Sie weitere Hilfe? </p>{" "}
          <p>
          Kontaktieren Sie uns telefonisch unter der Nummer{" "}
            <a
              className="ModalError__links--contactBox"
              href="tel:033756709705"
            >
              tel:  0337 567 09705
            </a>{" "}
            oder per E-Mail. Wir sind von Montag bis Freitag von 8 bis 16 Uhr für Sie da.
          </p>
        </div>
      </div>
    </div>,
    document.getElementById("portalError")
  );
};
