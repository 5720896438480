import React from "react";
import ReactDOM from "react-dom";
import styles from "./ModalDHLError.module.scss";

export const ModalDHLError = ({ isError, onClose, message }) => {
  if (!isError && !message) return null;
  return ReactDOM.createPortal(
    <div className={styles.ModalDHLError}>
      <div className={styles.closeBtnContainer}>
        <div
          className="ModalError__closeBtnContainer__closeBtn"
          onClick={onClose}
        ></div>
      </div>
      <p className={styles.ErrorTitle}>Es ist ein Fehler aufgetreten</p>
      <p className={styles.message}>
        <img
          src="/img/error_contact_icon.svg"
          alt="error icon"
          className={styles.icon}
        />
        {message.error}
      </p>
      <p className={styles.info}>
        Sie können das Formular erneut mit den korrigierten Daten ausfüllen.
        Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren
        Kundenservice unter der Telefonnummer{`${" "}`}
        <a href="tel:033756709705">
           0337 567 09705
        </a>
      </p>
      <button
        onClick={onClose}
        className={styles.button}
      >
        Schließen
      </button>
    </div>,
    document.getElementById("portalErrorDHL")
  );
};
