import { DatePicker } from '../../../../../DatePicker';
import './DeliveryDate.scss';
import { useFormContext } from 'react-hook-form';
import { addDays, getDay } from 'date-fns';
import { useEffect } from 'react';
import dayjs from 'dayjs';

export const DeliveryDate = () => {

  const { control, watch, register, getValues} = useFormContext({ shouldUseNativeValidation: true });
  const watchAllFields = watch();

  useEffect(() =>{}, [watchAllFields]);

  const values = getValues();

  //sposób zapiywania dat, które mają zostać wyłczone z kalendarza odbioru paczek: new Date(2023, 5, 2),
  // styczen - 0; luty - 1, marzec - 2, kwiecien - 3, maj - 4, czerwiec - 5,
  // lipiec - 6, sierpień - 7, wrzesień - 8, październik - 9, listopad - 10, grudzień -11

  const excludedDates = [
    new Date(2023, 0, 1), // Nowy Rok
    new Date(2023, 0, 6), // Święto Trzech Króli
    new Date(2023, 3, 9), // Wielkanoc
    new Date(2023, 3, 10), // Poniedziałek Wielkanocny
    new Date(2023, 4, 1), // Święto Pracy
    new Date(2023, 4, 3), // Święto Konstytucji 3 Maja
    new Date(2023, 4, 28), // Zielone Świątki
    new Date(2023, 5, 8), // Boże Ciało
    new Date(2023, 7, 15), // Wniebowzięcie Najświętszej Maryi Panny
    new Date(2023, 10, 1), // Wszystkich Świętych
    new Date(2023, 10, 11), // Święto Niepodległości
    new Date(2023, 11, 25), // Pierwszy dzień Bożego Narodzenia
    new Date(2023, 11, 26), // Drugi dzień Bożego Narodzenia
    new Date(2024, 0, 1), // Nowy Rok
    new Date(2024, 0, 6), // Święto Trzech Króli
    new Date(2024, 2, 31), // Wielkanoc
    new Date(2024, 3, 1), // Poniedziałek Wielkanocny
    new Date(2024, 4, 1), // Święto Pracy
    new Date(2024, 4, 3), // Święto Konstytucji 3 Maja
    new Date(2024, 4, 19), // Zielone Świątki
    new Date(2024, 4, 30), // Boże Ciało
    new Date(2024, 7, 15), // Wniebowzięcie Najświętszej Maryi Panny
    new Date(2024, 10, 1), // Wszystkich Świętych
    new Date(2024, 10, 11), // Święto Niepodległości
    new Date(2024, 11, 25), // Pierwszy dzień Bożego Narodzenia
    new Date(2024, 11, 26), // Drugi dzień Bożego Narodzenia
    new Date(2025, 0, 1), // Nowy Rok
    new Date(2025, 0, 6), // Święto Trzech Króli
  

  ];

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const date = () => {
    const currentTimeInBerlin = dayjs().tz('Europe/Berlin');
    const currentHour = currentTimeInBerlin.hour();
    if (currentHour >= 22) {
      const minDate = addDays(new Date(), 2);
      const maxDate = addDays(new Date(), 5);
      return { minDate, maxDate };
    }
    else {
      const minDate = addDays(new Date(), 1);
      const maxDate = addDays(new Date(), 4);
      return { minDate, maxDate };
    }
  };

  return (
    <div className="SelectionDataAndTime">
      <div className="SelectionDataAndTime__date">
        <DatePicker
          required
          control={control}
          placeholder="Abholdatum"
          name="dzien_odbioru"
          minDate={date().minDate}
          maxDate={date().maxDate}
          excludeDates={excludedDates}
          filterDate={isWeekday}
          register={register}
          // fetch={fetchDeliveryHours}
          value={values.dzien_odbioru}
        />
      </div>
      <div className="SelectionDataAndTime__time">
      </div>
    </div>
  );
};
