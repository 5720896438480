import './NavBar.scss';
import { Link } from 'react-scroll';
import { useState } from 'react';

const NavBar = () => {
  const [activeUser, setActiveUser] = useState(false);
  const [activeComplaintType, setActiveComplaintType] = useState(false);
  const [activeToolDetails, setActiveToolDetails] = useState(false);
  const [activePackageDetails, setActivePackageDetails] = useState(false);
  const [activeConfirmation, setActiveConfirmation] = useState(false);

  const scrollHandler = () => {
    if (window.scrollY < 629) {
      setActiveUser(true);
      setActiveComplaintType(false);
      setActiveToolDetails(false);
      setActivePackageDetails(false);
      setActiveConfirmation(false);
    } else if (window.scrollY >= 629 && window.scrollY < 1029) {
      setActiveUser(false);
      setActiveComplaintType(true);
      setActiveToolDetails(false);
      setActivePackageDetails(false);
      setActiveConfirmation(false);
    } else if (window.scrollY >= 1029 && window.scrollY < 1600) {
      setActiveUser(false);
      setActiveComplaintType(false);
      setActiveToolDetails(true);
      setActivePackageDetails(false);
      setActiveConfirmation(false);
    } else if (window.scrollY >= 1600 && window.scrollY < 2000) {
      setActiveUser(false);
      setActiveComplaintType(false);
      setActiveToolDetails(false);
      setActivePackageDetails(true);
      setActiveConfirmation(false);
    } else if (window.scrollY >= 2000) {
      setActiveUser(false);
      setActiveComplaintType(false);
      setActiveToolDetails(false);
      setActivePackageDetails(false);
      setActiveConfirmation(true);
    }
  };

  window.addEventListener('scroll', scrollHandler);


  return (
    <div className="NavBar">
      <ul className="NavBar__list">
        <Link
          to="UserDetails"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1000}
          className="NavBar__list__element"
        >
          <span
            className={
              activeUser
                ? 'NavBar__list__element--number__active one'
                : 'NavBar__list__element--number one'
            }
          ></span>
          <p>der Anmelder</p>
        </Link>
        <Link
          to="ComplaintType"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1000}
          className="NavBar__list__element"
        >
          <span
            className={
              activeComplaintType
                ? 'NavBar__list__element--number__active two'
                : 'NavBar__list__element--number two'
            }
          ></span>
          <p>Reklamationsart</p>
        </Link>
        <Link
          to="ToolDetails"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1000}
          className="NavBar__list__element"
        >
          <span
            className={
              activeToolDetails
                ? 'NavBar__list__element--number__active three'
                : 'NavBar__list__element--number three'
            }
          ></span>
          <p>Werkzeug</p>
        </Link>
        <Link
          to="PackageDetails"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1000}
          className="NavBar__list__element"
        >
          <span
            className={
              activePackageDetails
                ? 'NavBar__list__element--number__active four'
                : 'NavBar__list__element--number four'
            }
          ></span>
          <p>Paketabholung</p>
        </Link>
        <Link
          to="Confirmation"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1000}
          className="NavBar__list__element"
        >
          <span
            className={
              activeConfirmation
                ? 'NavBar__list__element--number__active five'
                : 'NavBar__list__element--number five'
            }
          ></span>
          <p>Bestätigung</p>
        </Link>
      </ul>
    </div>
  );
};

export default NavBar;
