import "./PackageDetails.scss";
import React, { useEffect, useState } from "react";
import { DeliveryDate, PackageSize } from "./components";
import { Section } from "../Section";
import { useFormContext } from "react-hook-form";

export const PackageDetails = () => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
    resetField,
  } = useFormContext();
  const [deliveryType, setDeliveryType] = useState("");
  const isCourier = deliveryType === "courier";
  const isParcelLocker = deliveryType === "parcelLocker";

  const handleDelivetyType = (type) => {
    setDeliveryType(type);
  };

  useEffect(() => {
    if(isCourier) (setValue("typprzesylki", "DHL"));
  }, [isCourier, setValue]);

  return (
    <Section
      title={
        <>
          <img src="/img/box_icon.svg" alt="box icon" />
          <h2 id="PackageDetails">Paketabholung</h2>
        </>
      }
      description="
      Wähle aus, ob du möchtest, dass der Kurier das Paket von der angegebenen Adresse abholt, oder ob du es lieber persönlich an einem Abgabepunkt aufgeben möchtest. Um die verfügbaren Abholzeiten des Kuriers anzuzeigen, gib die Adresse ein und wähle das Versanddatum aus."
    >
      <p>Wähle die Versandart für das Paket aus:</p>

      <div className="PackageDetails">
        <div
          onClick={() => {
            handleDelivetyType("courier");
            resetField("typprzesylki");
            resetField("rozmiar_paczki");
          }}
          className={
            isCourier
              ? "clickedDeliveryOption"
              : isParcelLocker
              ? "gray"
              : "PackageDetails__option"
          }
        >
          <img src="/img/car_icon.svg" alt="car icon" />
          <div className="PackageDetails__option--text">Abholung durch Dienstleister</div>
        </div>
        <div
          onClick={() => {
            handleDelivetyType("parcelLocker");
            resetField("typprzesylki");
            resetField("dzien_odbioru");
            resetField("godzina_odbioru");
            resetField("rozmiar_paczki");
          }}
          className={
            deliveryType === "parcelLocker"
              ? "clickedDeliveryOption"
              : isCourier
              ? "gray"
              : "PackageDetails__option"
          }
          
        >
          <img src="/img/paczkomat_icon.svg" alt="pointer icon" />
          <div className="PackageDetails__option--text">
          Packstation oder Paketversandpunkt
          </div>
        </div>
      </div>

      <div className="ChoosingCompany">
        
        {isCourier && (
          <div className="CompanyList"
          >
            {errors?.typprzesylki && <p className="error">Wählen Sie das Unternehmen aus.</p>}
            <div className="CompanyList__options">
              <label>
                <div
                  className={
                    watch("typprzesylki") === "DHL"
                      ? "clickedOption"
                      // : "CompanyList__options__option"
                      : "clickedOption" 
                  }
                >
                  <img src="img/logo_DHLKurier.png" alt="logo DHL" />
                </div>
                <input
                  {...register("typprzesylki", {
                    required: true,
                    
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="DHL"
                  
                />
              </label>
            </div>

            <div className="ChoosingDeliveryDateAndTime">
            <DeliveryDate />
            </div>
          </div>
        )}
        {isParcelLocker && (
          <div className="CompanyList">
            {errors?.typprzesylki && <p className="error">Wählen Sie das Unternehmen aus.</p>}

            <div className="CompanyList__options">
              <label>
                <input
                  {...register("typprzesylki", {
                    required: true,      
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="dhl pop"
                  defaultChecked
                />
                <div
                  className={
                    watch("typprzesylki") === "dhl pop"
                      ? "clickedOption"
                      // : "CompanyList__options__option"
                      : "clickedOption"
                  }
                >
                  <img src="img/DHL_Packstation_logo.png" alt="logo DHL" />
                </div>
              </label>
            </div>
          </div>
        )}
      </div>

      {watch("typprzesylki") === "dhl pop" ||
      deliveryType === "parcelLocker" ? (
        <div className="massage">
          Nach Bestätigung der Anmeldung wird der Frachtbrief an die angegebene E-Mail-Adresse gesendet. Um das Paket zu versenden, gehen Sie zu einer DHL-Annahmestelle. Das Paket können Sie in jedem Packstation-Automaten, Paketbox oder in einer DHL-Filiale abgeben.
        </div>
      ) : null}

      <p>Verpackungskategorie:</p>

      {errors.rozmiar_paczki && <p className="error">Wählen Sie die Paketgröße aus.</p>}

      {deliveryType === "parcelLocker" ? (
        <div className="packageSize">
          <PackageSize
            size="kleine (S) 64 x 38 x 8 cm"
            icon={<img src="img/small_box_icon.svg" alt="small box icon" />}
            packageDescription="kleine (S)"
            range="64 x 38 x 8 cm"
            isActive={watch("rozmiar_paczki") === "kleine (S) 64 x 38 x 8 cm"}
            register={register}
          />
          <PackageSize
            size="mittlere (M) 64 x 38 x 19 cm"
            icon={<img src="img/medium_box_icon.svg" alt="medium box icon" />}
            packageDescription="mittlere (M)"
            range="64 x 38 x 19 cm"
            isActive={watch("rozmiar_paczki") === "mittlere (M) 64 x 38 x 19 cm"}
            register={register}
          />
          <PackageSize
            size="großes (L) 64 x 38 x 41 cm"
            icon={<img src="img/big_box_icon.svg" alt="big box icon" />}
            packageDescription="großes (L)"
            range="64 x 38 x 41 cm"
            isActive={watch("rozmiar_paczki") === "großes (L) 64 x 38 x 41 cm"}
            register={register}
          />
          <PackageSize
            size="Palette"
            icon={<img src="img/pallet_icon.svg" alt="pallete icon" />}
            packageDescription="Palette"
            range="-"
            isDisabled={isParcelLocker}
            isActive={watch("rozmiar_paczki") === "Palette"}
            register={register}
          />
        </div>
      ) : (
        <div className="packageSize">
          <PackageSize
            size="kleine (Bis zu 5 kg)"
            icon={<img src="img/small_box_icon.svg" alt="small box icon" />}
            packageDescription="kleine"
            range="Bis zu 5 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "kleine (Bis zu 5 kg)"}
            register={register}
          />
          <PackageSize
            size="mittlere (Bis zu 10 kg)"
            icon={<img src="img/medium_box_icon.svg" alt="medium box icon" />}
            packageDescription="mittlere"
            range="Bis zu 10 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "mittlere (Bis zu 10 kg)"}
            register={register}
          />
          <PackageSize
            size="großes (Bis zu 20 kg)"
            icon={<img src="img/big_box_icon.svg" alt="big box icon" />}
            packageDescription="großes"
            range="Bis zu 20 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "großes (Bis zu 20 kg)"}
            register={register}
          />
          <PackageSize
            size="Palette"
            icon={<img src="img/pallet_icon.svg" alt="pallete icon" />}
            packageDescription="Palette"
            range=" "
            isActive={watch("rozmiar_paczki") === "Palette"}
            register={register}
          />
        </div>
      )}
    </Section>
  );
};