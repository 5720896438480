import './DatePicker.scss';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import de from 'date-fns/locale/de';

dayjs.extend(utc);
dayjs.extend(timezone);

// import pl from 'date-fns/locale/pl';
// registerLocale('pl', pl);
registerLocale('de', de);

export const DatePicker = ({
  required,
  control,
  placeholder,
  name,
  minDate,
  maxDate,
  filterDate,
  excludeDates,
  fetch,
  value
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const { register, formState: {errors} } = useFormContext();




  return (
    <div className="PurchaseDate">
      <span className="PurchaseDate--icon">
        <img src="/img/calendar_icon.svg" alt="calendar icon"></img>
      </span>

      <Controller
        name={name}
        control={control}
        // defaultValue={new Date()}
        {...register(name)}
        rules={required ? { required: 'Pflichtfeld' } : {}}
        render={({ field: { onChange } }) => {
          return (
            <BaseDatePicker
              className="PurchaseDate__DatePicker long"
              selected={startDate}
              disabledKeyboardNavigation
              onChange={(data) => {
                const currentTimeInBerlin = dayjs().tz('Europe/Berlin');
                const selectedDate = dayjs(data).tz('Europe/Berlin');

                // Sprawdź, czy aktualny czas jest po 22:00
                if (currentTimeInBerlin.hour() >= 22) {
                  // Ustaw wybraną datę na następny dzień
                  const nextDay = selectedDate.add(1, 'day');
                  onChange(nextDay.format('YYYY-MM-DD'));
                  setStartDate(nextDay.toDate());
                } else {
                  onChange(selectedDate.format('YYYY-MM-DD'));
                  setStartDate(selectedDate.toDate());
                }
              }}
              filterDate={filterDate}
              placeholderText={placeholder}
              excludeDates={excludeDates}
              minDate={minDate}
              maxDate={maxDate}
              value={value}
              locale="de"
              dateFormat="dd.MM.yyyy"

            />
          );
        }}
      />
      {errors[name] && <p className="error">{errors[name].message}</p>}
    </div>
  );
};
