import "./UserDetails.scss";
import React, { useState } from "react";
import { Section } from "../Section/Section";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { Input } from "../Input/Input";

export const UserDetails = () => {
  const {
    control,
    register,
    formState: { errors },
    resetField,
  } = useFormContext();
  const [personalData, setPersonalData] = useState(true);
  const [companyData, setCompanyData] = useState(false);

  const handlePersonalData = () => {
    setPersonalData(true);
    setCompanyData(false);
    resetField("nip");
    resetField("nazwafirmy");
  };

  const handleCompanyData = () => {
    setPersonalData(false);
    setCompanyData(true);
  };

  const required = {
    value: true,
    message: "Pflichtfeld",
  };

  return (
    <Section
      title={
        <>
          <img src="/img/person_icon.svg" alt="person icon" />
          <h2 id="UserDetails">der Anmelder</h2>
        </>
      }
      description={
        "Geben Sie Ihre Daten, Adresse und Kontaktnummer ein. Wenn Sie eine Reparatur im Namen eines Unternehmens melden, werden alle mit der Reparatur verbundenen Dokumente auf die angegebene Einheit ausgestellt."
      }
    >
      <div>
        <div className="UserDetails__form--title">
          <button
            type="button"
            className={
              personalData
                ? " UserDetails__form--title--button UserDetails__form--title--clicked"
                : "UserDetails__form--title--button UserDetails__form--title--option"
            }
            onClick={handlePersonalData}
          >
            <p className="UserDetails__form--title--text">Privatperson</p>
          </button>
          <button
            type="button"
            className={
              companyData
                ? "UserDetails__form--title--button UserDetails__form--title--clicked"
                : "UserDetails__form--title--button UserDetails__form--title--option"
            }
            onClick={handleCompanyData}
          >
            <p className="UserDetails__form--title--button--text">Unternehmen</p>
          </button>
        </div>
        <div className="UserDetails__form__data--line">
          <Input
            inputName="imie"
            className="long"
            placeholder="Vorname"
            type="text"
            error={errors?.imie}
            validationSchema={{
              required: required,
              maxLength: { value: 35, message: "Max. 35 Zeichen" },
            }}
          />

          {/* <label className="label">
            <input
              {...register("imie", {
                required: {
                  value: true,
                  message: "Pflichtfeld",
                },
                maxLength: {
                  value: 35,
                  message: "Maksymalnie 35 znaków",
                },
              })}
              className="long"
              type="text"
              placeholder="Imię"
              name="imie"
            />
            {errors.imie?.message && (
              <p className="error">{errors.imie?.message}</p>
            )}
          </label> */}

          <Input
            inputName="nazwisko"
            className="long"
            placeholder="Nachname"
            type="text"
            error={errors?.nazwisko}
            validationSchema={{
              required: required,
              maxLength: { value: 35, message: "Max. 35 Zeichen" },
            }}
          />

          {/* <label className="label">
            <input
              {...register("nazwisko", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 5, message: "Maksymalnie 46 znaków" },
              })}
              className="long"
              type="text"
              placeholder="Nazwisko"
              name="nazwisko"
            />
            {errors.nazwisko?.message && (
              <p className="error">{errors.nazwisko?.message}</p>
            )}
          </label> */}
        </div>
        {personalData ? (
          <></>
        ) : (
          <div className="UserDetails__form__data--line">
            <Input
              inputName="nazwafirmy"
              className="long"
              placeholder="Firmenname"
              type="text"
              error={errors?.nazwafirmy}
              validationSchema={{
                required: required,
                maxLength: { value: 46, message: "Max. 35 Zeichen" },
              }}
            />
            {/* <label className="label">
              <input
                {...register("nazwafirmy", {
                  required: { value: true, message: "Pflichtfeld" },
                  maxLength: { value: 46, message: "Maksymalnie 46 znaków" },
                })}
                className="long"
                type="text"
                placeholder="Nazwa firmy"
                name="nazwafirmy"
              />
              {errors.nazwafirmy?.message && (
                <p className="error">{errors.nazwafirmy?.message}</p>
              )}
            </label> */}

            <label className="label">
              <InputMask
                mask="999999999"
                maskChar=""
                {...register("nip", {
                  required: { value: true, message: "Pflichtfeld" },
                  minLength: {
                    value: 9,
                    message: "Geben Sie die 9-stellige Steuernummer ein.",
                  },
                })}
                className="long"
                placeholder="USt-IdNr."
                name="nip"
              ></InputMask>
              {errors.nip?.message && (
                <p className="error">{errors.nip?.message}</p>
              )}
            </label>
          </div>
        )}

        <div className="UserDetails__form__data--line">
          <Input
            inputName="nadawcaulica"
            className="verylong"
            placeholder="Straße"
            type="text"
            error={errors?.nadawcaulica}
            validationSchema={{
              required: required,
              maxLength: { value: 50, message: "Max. 50 Zeichen" },
              onChange: (e) => {
                // resetField("godzina_odbioru");
                resetField("dzien_odbioru");
              },
            }}
          />

          {/* <label className="label">
            <input
              {...register("nadawcaulica", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 50, message: "Maksymalnie 50 znaków" },
                onChange: (e) => {
                  resetField("godzina_odbioru");
                  resetField("dzien_odbioru");
                },
              })}
              className="long"
              type="text"
              name="nadawcaulica"
              placeholder="Ulica"
            />
            {errors.nadawcaulica?.message && (
              <p className="error">{errors.nadawcaulica?.message}</p>
            )}
          </label> */}
          <div className="houseNumber">
          <Input
            inputName="nadawcanrdomu"
            className="short"
            placeholder="Hausnummer"
            type="text"
            error={errors?.nadawcanrdomu}
            validationSchema={{
              required: required,
              maxLength: { value: 8, message: "Max. 8 Zeichen" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
              },
            }}
          />
           {/* <Input
            inputName="nadawcanrlokalu"
            className="short"
            placeholder="Wohnungsnummer"
            type="text"
            error={errors?.nadawcanrlokalu}
            validationSchema={{
              // required: required,
              maxLength: { value: 5, message: "Max. 5 Zeichen" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
              },
            }}
          /> */}
          </div>

          {/* <div className="houseNumber">
            <label className="label">
              <input
                {...register("nadawcanrdomu", {
                  required: { value: true, message: "Pflichtfeld" },
                  maxLength: { value: 10, message: "Max 10 znaków" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
                  },
                })}
                className="short"
                type="text"
                placeholder="Nr domu"
                name="nadawcanrdomu"
                min={1}
              />
              {errors.nadawcanrdomu?.message && (
                <p className="error">{errors.nadawcanrdomu?.message}</p>
              )}
            </label>

            <label className="label">
              <input
                {...register("nadawcanrlokalu", {
                  maxLength: { value: 5, message: "Max 5 znaków" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
                  },
                })}
                className="short"
                type="text"
                placeholder="Nr lokalu"
                name="nadawcanrlokalu"
                min={1}
              />
              {errors.nadawcanrlokalu?.message && (
                <p className="error">{errors.nadawcanrlokalu?.message}</p>
              )}
            </label>
          </div> */}
        </div>
        <div className="UserDetails__form__data--line">
          <label className="label">
            <InputMask
              mask="99999"
              maskChar=""
              {...register("nadawcakodpocztowy", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 5, message: "Max. 5 Zeichen" },
                onChange: (e) => {
                  resetField("godzina_odbioru");
                  resetField("dzien_odbioru");
                },
              })}
              className={window.innerWidth < 768 ? "longZipCode" : "short"}
              placeholder="Postleitzahl"
              name="nadawcakodpocztowy"
            ></InputMask>
            {errors.nadawcakodpocztowy?.message && (
              <p className="error">{errors.nadawcakodpocztowy.message}</p>
            )}
          </label>
          <label className="label">
            <input
              {...register("nadawcamiasto", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 35, message: "Max. 35 Zeichen" },
                onChange: (e) => {
                  resetField("godzina_odbioru");
                  resetField("dzien_odbioru");
                },
              })}
              className="verylong"
              type="text"
              placeholder="Ort"
              name="nadawcamiasto"
            />

            {errors.nadawcamiasto?.message && (
              <p className="error">{errors.nadawcamiasto.message}</p>
            )}
          </label>
        </div>
        <div className="UserDetails__form__data--line">
          <label className="label">
          <InputMask
    mask="999999999999999"
    maskChar=""
    {...register("tel", {
      required: { value: true, message: "Pflichtfeld" },
      minLength: { value: 9, message: "Min. 9 Zeichen" },
      pattern: {
        value: /^\d{9,}$/,
        message: "Geben Sie eine gültige Telefonnummer ein.",
      },
    })}
    className="long"
    type="tel"
    placeholder="Telefon"
    name="tel"
  />
        {errors.tel?.message && (
          <p className="error">{errors.tel.message}</p>
        )}
          </label>
          <label className="label">
            <input
              {...register("email", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 128, message: "Max. 128 Zeichen" },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Geben Sie eine gültige E-Mail-Adresse ein.",
                },
              })}
              className="long"
              type="email"
              placeholder="E-mail"
              name="email"
            />
            {errors.email?.message && (
              <p className="error">{errors.email.message}</p>
            )}
          </label>
        </div>
      </div>
    </Section>
  );
};
