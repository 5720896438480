import './App.scss';
import NavBar from './components/NavBar/NavBar';
import Header from './components/Header/Header';
import { Form } from './components/Form';
import { useEffect, useState } from 'react';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const responsive = {
    breakpointDesktop: width >= 1024,
    breakpointTabletHorizontal: width < 1024 && width >= 768,
    breakpointTablet: width < 768 && width >= 390,
    breakpointMobile: width < 390,
  };

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () =>
      window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, [width, setWidth]);

  return (
    <div className="App">
      <div id="portal" />
      <div id="portalThankYou" />
      <div id="portalError" />
      <div id="portalSpinner" />
      <div id="portalErrorDHL" />
      {/* <Header /> */}
      {responsive.breakpointDesktop ? (
        <Header banner={<img src="/img/baner.webp" alt="banner" />} />
      ) : responsive.breakpointTabletHorizontal ? (
        <Header banner={<img src="img/baner_1024px.webp" alt="banner" />} />
      ) : responsive.breakpointTablet ? (
        <Header banner={<img src="/img/baner_768pxpng.webp" alt="banner" />} />
      ) : (
        <Header banner={<img src="/img/baner_390px.webp" alt="banner" />} />
      )}
      <div className="main">
        <NavBar />
        <Form />
      </div>
    </div>
  );
}

export default App;

