import { Section } from "../Section";
import { DatePicker } from "../../../DatePicker";
import "./ToolDetails.scss";
import { useState, useRef } from "react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { addDays, subYears } from "date-fns";
import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";

export const ToolDetails = ({ maxDate, minDate }) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    getValues,
  } = useFormContext();

  const values = getValues();

  const doData = () => {
    console.log(watch("narzedzia[0].datazakupu"))
  }

  return (
    <Section
      title={
        <>
          <img src="/img/drill_icon.svg" alt="drill icon" />
          <h2 id="ToolDetails">Werkzeug</h2>
        </>
      }
      description="Geben Sie Informationen über das Gerät ein und beschreiben Sie den Defekt genau. Im Falle einer Garantiereparatur fügen Sie bitte den Kaufbeleg bei und optional einen Zertifikat der 3-Jahres-Garantie."
    >
      <div className="toolDetails">
        <div className="line">
          <label className="label">
            <input
              {...register("narzedzia[0].modeltowaru", {
                required: { value: true, message: "Pflichtfeld" },
                maxLength: { value: 50, message: "Max. 50 Zeichen" },
              })}
              className="long"
              type="text"
              name="narzedzia[0].modeltowaru"
              placeholder="Modell"
            ></input>
            {!!errors.narzedzia?.length && errors.narzedzia[0].modeltowaru?.message && (
              <p className="error">{errors.narzedzia[0].modeltowaru.message}</p>
            )}
          </label>

          <label className="label">
            <input
              {...register("narzedzia[0].nrseryjny", { maxLength: {value: 25, message: "Max. 25 Zeichen"} })}
              className="long"
              type="text"
              name="narzedzia[0].nrseryjny"
              placeholder="Seriennummer (optional)"
            ></input>
             {!!errors.narzedzia?.length && errors.narzedzia[0].nrseryjny?.message && (
              <p className="error">{errors.narzedzia[0].nrseryjny.message}</p>
            )}
          </label>
        </div>

        {watch("narzedzia[0].typzgloszenia") === "gwarancja" ? (
          <div className="guaranteeData">
            <DatePicker
              placeholder={"Kaufdatum (optional)"}
              name="narzedzia[0].datazakupu"
              minDate={subYears(new Date(), 3)}
              maxDate={new Date()}
              register={register}
              value={watch("narzedzia[0].datazakupu")}
              fetch={doData}
            />
            <div className="guaranteeData__proof">
              <div className="guaranteeData__proof--file">
                <label htmlFor="receipt">
                Kaufbeleg
                  <img src="/img/plus_icon.svg" alt="plus icon"></img>
                </label>
                <span>{watch("narzedzia[0].skandowodu")[0]?.name}</span>
                <input
                  {...register("narzedzia[0].skandowodu")}
                  accept=".jpg, .jpeg, .png, .pdf"
                  type="file"
                  id="receipt"
                  name="narzedzia[0].skandowodu"
                />
              </div>
              <div className="guaranteeData__proof--file">
                <label htmlFor="certificate">
                3-Jahres-Zertifikat
                  <img src="/img/plus_icon.svg" alt="plus icon"></img>
                </label>
                <span>{watch("narzedzia[0].skancertyfikatu")[0]?.name}</span>
                <input
                  {...register("narzedzia[0].skancertyfikatu", {
                    required: false,
                  })}
                  accept=".jpg, .jpeg, .png, .pdf"
                  type="file"
                  id="certificate"
                  name="narzedzia[0].skancertyfikatu"
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="description">
          <label className="description__title" htmlFor="description">
          Fehlerbeschreibung
          </label>
          <textarea
            {...register("narzedzia[0].opisusterki", {
              required: {value: true, message: "Pflichtfeld"},
              maxLength: {value: 500, message: "Maksymalnie 500 znaków"},
            })}
            className="description__data"
            type="text"
            name="narzedzia[0].opisusterki"
          ></textarea>
          {!!errors.narzedzia?.length && errors.narzedzia[0].opisusterki?.message && (
            <p className="error">{errors.narzedzia[0].opisusterki.message}</p>
          )}
        </div>
        {watch("narzedzia[0].typzgloszenia") === "pogwarancyjna" ? (
          <div className="maxPrice">
            <div className="maxPrice__description">
              <img
                src="img/questionMark_icon.svg"
                alt="question mar icon"
              ></img>
              <p>
              Maximalbetrag, bis zu dem Sie der Reparatur zustimmen {" "}
                <span>(optional)</span>
              </p>
            </div>
            <select
              {...register("narzedzia[0].maksymalnykoszt")}
              className="short maxPrice__select"
              type="number"
              step="1"
              placeholder="Kwota"
              min={0}
              name="narzedzia[0].maksymalnykoszt"
            >
              <option>100</option>
              <option>200</option>
              <option>300</option>
              <option>400</option>
              <option>500</option>
              <option>600</option>
              <option>700</option>
              <option>800</option>
              <option>900</option>
              <option>1000</option>
            </select>
          </div>
        ) : null}
      </div>
    </Section>
  );
};
